<template>
  <div class="">
    <a-row :gutter="10">
      <a-col span="18">
        <a-card :bodyStyle="{'height' : '793px'}">
          <div id="homeMap" class="home-map"></div>
          <div class="window" v-if="pointInfo.top" :style="{'top': pointInfo.top + 'px', 'left': pointInfo.left + 'px'}">
            <template v-if="projectType === 3">
              <div class="window-title">
                <span>{{ pointInfo.projectName }}</span>
                <a-icon type="close" @click="closeWindow"/>
              </div>
              <div class="margin-t15">
                <div class="display-f">
                  <span class="width-80">项目进度</span>
                  <div class="flex1 width-0">
                    <a-progress :percent="pointInfo.progressValue || 0"/>
                  </div>
                </div>
                <div class="display-f">
                  <span class="width-80">里程碑进度</span>
                  <div class="flex1 width-0">
                    <a-progress
                        :percent="pointInfo.finishedMilestoneNum / pointInfo.totalMilestoneNum * 100"
                        :format="() => `${(pointInfo.finishedMilestoneNum || 0) + '/' + (pointInfo.totalMilestoneNum || 0)}`"
                    />
                  </div>
                </div>
                <div class="display-f">
                  <span class="width-80">任务进度</span>
                  <div class="flex1 width-0">
                    <a-progress
                        :percent="pointInfo.finishedTaskNum / pointInfo.totalTaskNum * 100"
                        :format="() => `${(pointInfo.finishedTaskNum || 0) + '/' + (pointInfo.totalTaskNum || 0)}`"

                    />
                  </div>
                </div>
                <div class="display-f">
                  <span class="width-80">处理问题</span>
                  <div class="flex1 width-0">
                    <a-progress
                        :percent="pointInfo.finishedProblemNum / pointInfo.totalProblemNum * 100"
                        :format="() => `${(pointInfo.finishedProblemNum || 0) + '/' + (pointInfo.totalProblemNum || 0)}`"
                    />
                  </div>
                </div>
                <div class="display-f">
                  <span class="width-80">预算支出</span>
                  <div class="flex1 width-0">
                    <a-progress :percent="pointInfo.planExpenditure || 0"/>
                  </div>
                </div>
                <div class="display-f" v-for="(item, index) in pointInfo.projectExpenditureDatas" :key="index">
                  <span class="width-80">{{ item.expenditureCategoryName }}</span>
                  <div class="flex1 width-0">
                    <a-progress :percent="item.expenditurePercentage || 0"/>
                  </div>
                </div>

              </div>
            </template>
            <template v-else>
              <div class="window-title">
                <span>{{ pointInfo.province || pointInfo.city }}</span>
                <a-icon type="close" @click="closeWindow"/>
              </div>
              <div class="margin-t15">
                <div>进行中项目: {{ pointInfo.goingProjectNum }}</div>
                <div class="margin-t5">已完成项目：{{ pointInfo.finishedProjectNum }}</div>
                <div class="margin-t5">进行中任务：{{ pointInfo.goingTaskNum }}</div>
                <div class="margin-t5">已完成任务：{{ pointInfo.finishedTaskNum }}</div>
              </div>
            </template>
          </div>
        </a-card>
      </a-col>
      <a-col span="6">
        <a-card title="项目概况" :bodyStyle="{'height' : '200px'}">
          <div class="height-200" id="pieChart1"></div>
        </a-card>
        <a-card title="任务概况" :bodyStyle="{'height' : '200px'}" style="margin-top: 10px">
          <div class="height-200" id="pieChart2"></div>
        </a-card>
        <a-card title="进度情况" :bodyStyle="{'height' : '200px'}" style="margin-top: 10px">
          <div class="height-200" id="barChart3"></div>
        </a-card>
      </a-col>
    </a-row>
    <a-row class="margin-t15">
      <a-card>
        <div class="padding-15">
          <div class="display-f align-items-c padding-15" v-for="(item, index) in detail.noticeList" :key="item.id">
            <a-icon :style="{'font-size' : '18px'}" type="info-circle" @click="closeWindow" />
            <span class="margin-l10">{{ item.noticeTitle }}</span>
            <span class="flex1 margin-l10"> {{ item.noticeContent | texts }}</span>
            <span class="margin-l10">{{ item.createTime }}</span>
          </div>
        </div>
      </a-card>
    </a-row>
  </div>
</template>

<script>
import { getHomeDetail } from '../../api/home/home'
import districtList from '@/assets/lib/district/district.json'
export default {
  name: 'home',
  data() {
    return {
      detail: {
        projectInfoList: [],
        projectOverview: {},
        taskOverview: {},
        progressOverviews: [],
        noticeList: [],
        zoomCache: 1
      },
      projectStatusName: ['', '洽谈中', '规划中', '实施中', '验收中', '已完成', '已取消', '已搁置'],
      zoom: 12,
      center: [121.59996, 31.197646],
      events: {
        'click': () => {
          alert('map clicked');
        }
      },
      pointInfo: {},
      pointList: {},
      provinceList: [],
      cityList: [],
      projectList: [],
      projectType: 1
    }
  },
  mounted () {
    this.getDetail()
    this.initMap()
  },
  methods: {
    getDetail () {
      getHomeDetail({type: 1}).then(res => {
        this.detail = res.data
        this.provinceList = [...res.data.provinceOverviewList]
        if (res.data.cityOverviewList) this.cityList = [...res.data.cityOverviewList]
        this.projectList = [...res.data.projectInfoList]
        this.addPoint(1)
        this.drawChart()
        this.zoomCache = 1
      })
    },
    drawChart () {
      const pieChart1 = this.$echarts.init(document.getElementById('pieChart1'))
      const option1 = {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '项目概况',
            type: 'pie',
            data: [
              { value: this.detail.projectOverview.goingNum, name: `进行中的项目` },
              { value: this.detail.projectOverview.finishedNum, name: `已完成的项目`}
            ],
            labelLine: {
              show: true
            },
            label: {
              show: true
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      pieChart1.setOption(option1)
      pieChart1.on("click",  (params) => {
        if(params.data.name === "进行中的项目") {
          this.$router.push(`/project/projectList?type=3`)
        } else {
          this.$router.push(`/project/projectList?type=5`)
        }
      })

      const pieChart2 = this.$echarts.init(document.getElementById('pieChart2'))
      const option2 = {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '任务概况',
            type: 'pie',
            data: [
              { value: this.detail.taskOverview.goingNum, name: `进行中的任务` },
              { value: this.detail.taskOverview.finishedNum, name: `已完成的任务`}
            ],
            labelLine: {
              show: true
            },
            label: {
              show: true
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      pieChart2.setOption(option2)
      pieChart2.on("click",  (params) => {
        if(params.data.name === "进行中的任务") {
          this.$router.push(`/task?type=2`)
        } else {
          this.$router.push(`/task?type=3`)
        }
      })

      let xData = [], delayProject = [], delayTask = [], normalProject = [], normalTask = []
      this.detail.progressOverviews.forEach(item => {
        xData.push(item.month)
        delayProject.push(item.delayProjectNum)
        delayTask.push(item.delayTaskNum)
        normalProject.push(item.normalProjectNum)
        normalTask.push(item.normalTaskNum)
      })
      const barChart3 = this.$echarts.init(document.getElementById('barChart3'))
      let option3 = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: xData
        },
        series: [
          {
            name: '项目延期',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: delayProject
          },
          {
            name: '项目正常',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: normalProject
          },
          {
            name: '任务延期',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: delayTask
          },
          {
            name: '任务正常',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: normalTask
          }
        ]
      };
      barChart3.setOption(option3)
    },
    initMap () {
      //定义地图中心点坐标
      var center = new TMap.LatLng(38.77493,105.847677)
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      const map = this.map = new TMap.Map(document.getElementById('homeMap'), {
        center: center,//设置地图中心点坐标
        zoom: 4.6,   //设置地图缩放级别
        mapStyleId: 'style1'
      });
      map.on("panstart", () => {
        this.pointInfo = {}
      })
      map.on('zoom_changed', (e, value) => {
        this.pointInfo = {}
        if (e.zoom < 6.5) {
          if (this.zoomCache !== 1) this.addPoint(1)
        } else if (e.zoom >= 6.5 && e.zoom < 9) {
          if (this.zoomCache !== 2) this.addPoint(2)
        } else {
          if (this.zoomCache !== 3) this.addPoint(3)
        }
      })
      this.initPoint()
    },
    initPoint () {
      //创建并初始化MultiMarker
      const markerLayer = this.markerLayer =  new TMap.MultiMarker({
        map: this.map,  //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          "myStyle": new TMap.MarkerStyle({
            "width": 22,  // 点标记样式宽度（像素）
            "height": 29, // 点标记样式高度（像素）
            "src": './image/marker.png',  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            "anchor": { x: 12, y: 32 }
          }),
          "bigPoint" : new TMap.MarkerStyle({
            "width": 44,  // 点标记样式宽度（像素）
            "height": 58, // 点标记样式高度（像素）
            "src": './image/marker.png',  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            "anchor": { x: 12, y: 32 }
          }),
        }
      });
      markerLayer.on("click", (e) => {
        this.pointInfo = e.geometry.properties
        this.pointInfo.top = e.point.y - 230
        this.pointInfo.left = e.point.x - 100
        this.$forceUpdate()
      })
    },
    addPoint (type) {
      this.projectType = type
      if (this.markerLayer) this.markerLayer.setGeometries([])
      switch (type) {
        case 1:
          this.pointList = [ ...this.provinceList ]
          this.zoomCache = 1
              break
        case 2:
          this.pointList = [ ...this.cityList ]
          this.zoomCache = 2
              break
        case 3:
          this.pointList = [ ...this.projectList ]
          this.zoomCache = 3
              break
      }
      let markers = []
      this.pointList.forEach((v, i) => {
        markers.push({
          'id': i,   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
          'styleId': 'myStyle',  //指定样式id
          'position': new TMap.LatLng(v.latitude, v.longitude),  //点标记坐标位置
          'properties': v
        })
      })
      this.markerLayer.add(markers)
    },
    closeWindow () {
      this.pointInfo = {}
    }
  }
}
</script>

<style scoped lang="scss">

.wrapper {
  /deep/ .ant-card-body {
    padding: 0;
    height: 100%;
    .home-map {
      height: 100%;
    }
  }
  .window{
    position: absolute;
    width: 200px;
    height: 200px;
    background-color: $white;
    padding: 10px 15px;
    box-sizing: border-box;
    z-index: 99999;
    &::after {
      content: ' ';
      position: absolute;
      left: calc(50% - 10px);
      bottom: -10px;
      width: 0;
      height: 0;
      border-top: 10px solid $white;
      border-left: 10px solid transparent;
    }
    .window-title{
      position: relative;
      font-size: 16px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
    }
  }
}


</style>
