import request from '@/utils/request'

export function getHomeDetail(params) {
    return request({
        url: '/digitalCockpit/overview',
        method: 'GET',
        params,
    })
}





